import { ref, computed, watch, provide, inject } from 'vue';
import { i18n } from '@/plugins/i18n';
import { useUtils } from '@/plugins/i18n/utils';
import router from '@/router';
import Authentication from 'lib-security/src/lib-components/security.vue';
import { EventBus } from '@/plugins/event-bus';

export default function settings() {
  // i18n
  const { t } = useUtils();

  const lang = computed(() => {
    return i18n.locale;
  })

  const realm = computed(() => {
    var id = window.location.hostname;
    if (id === 'localhost') {
      id = 'gemsys-dev.geminys.com'
    }
    return id;
  })

  const basePath = computed(() => {
    var array = [];
    array.push({
      text: i18n.t('Home'),
      to: '/',
    })
    return array;
  })

  const roles = computed(() => {
    var array = [];
    try {
      var tokenParsed = Authentication.methods.getTokenParsed();
      array = tokenParsed.realm_access.roles;
    } catch (error) {
      console.log(error);
    } finally {
      return array;
    }
  })

  const adminSettingsMenu = computed(() => {
    var children = [];
    if (roles.value.includes('hermes-client-management')) {
      children.push({
        title: t('ClientManagement'),
        icon: 'account_circle',
        to: {
          name: 'client-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-user-management')) {
      children.push({
        title: t('UserManagement'),
        icon: 'person',
        to: {
          name: 'user-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-group-management')) {
      children.push({
        title: t('GroupManagement'),
        icon: 'group',
        to: {
          name: 'group-management',
          params: {},
        },
      })
    }
    /*if (roles.value.includes('hermes-comment-management')) {
            children.push({
                title: t('Comments'),
                icon: 'document',
                to: {
                    name: '',
                    params: {},
                },
            });
        }*/
    if (roles.value.includes('hermes-subscription-management')) {
      children.push({
        title: t('SubscriptionManagement'),
        icon: 'mail',
        to: {
          name: 'subscription-management',
          params: {},
        },
      })
    } /*
        //if (roles.value.includes('hermes-document-management')) {
            children.push({
                title: t('Documents'),
                icon: 'document',
                to: {
                    name: '',
                    params: {},
                },
            });
        //}*/
    if (roles.value.includes('hermes-document-list')) {
      children.push({
        title: t('DocumentList'),
        icon: 'description',
        to: {
          name: 'document-list',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-model-management')) {
      children.push({
        title: t('ManageModels'),
        icon: 'article',
        to: {
          name: 'manage-models',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-model-values-management')) {
      children.push({
        title: t('ManageModelOptions'),
        icon: 'tune',
        to: {
          name: 'manage-model-options',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-translation-agency-management')) {
      children.push({
        title: t('TranslationAgencyManagement'),
        icon: 'translate',
        to: {
          name: 'translation-agency-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-translation-management')) {
      children.push({
        title: t('TranslationManagement'),
        icon: 'translate',
        to: {
          name: 'translation-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-cache-generate')) {
      children.push({
        title: t('CacheManagement'),
        icon: 'cached',
        to: {
          name: 'cache-management',
          params: {},
        },
      })
    }
    /*//if (roles.value.includes('hermes-?-management')) {
            children.push({
                title: t('Import/Export'),
                icon: 'document',
                to: {
                    name: '',
                    params: {},
                },
            });
        //}
        if (roles.value.includes('hermes-translation-management')) {
            children.push({
                title: t('Translation'),
                icon: 'document',
                to: {
                    name: '',
                    params: {},
                },
            });
        }*/
    if (roles.value.includes('hermes-editor')) {
      children.push({
        title: t('ImageRepository'),
        icon: 'photo_library',
        to: {
          name: 'image-repository',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-usage-report')) {
      children.push({
        title: t('UsageReport'),
        icon: 'history',
        to: {
          name: 'usage-report',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-notification-report')) {
      children.push({
        title: t('NotificationReport'),
        icon: 'breaking_news',
        to: {
          name: 'notification-report',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-search-admin')) {
      children.push({
        title: t('SearchAdmin'),
        icon: 'manage_search',
        to: {
          name: 'search-admin',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-tag-management')) {
      children.push({
        title: t('TagManagement'),
        icon: 'label',
        to: {
          name: 'tag-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-plan-view')) {
      children.push({
        title: t('Plan'),
        icon: 'assignment',
        to: {
          name: 'client-plan',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-composer')) {
      children.push({
        title: t('IndexManagement'),
        icon: 'toc',
        to: {
          name: 'index-view',
          params: {},
        },
      })
      children.push({
        title: t('CompositionManagement'),
        icon: 'hive',
        to: {
          name: 'composition-view',
          params: {},
        },
      })
      if (roles.value.includes('hermes-offer')) {
        children.push({
          title: t('OfferManagement'),
          icon: 'receipt',
          to: {
            name: 'offer-view',
            params: {},
          },
        })
      }
      if (roles.value.includes('hermes-view-automatic-composition-logs')) {
        children.push({
          title: t('AutomaticCompositionLogs'),
          icon: 'archive',
          to: {
            name: 'composition-automatic-log-view',
            params: {},
          },
        })
      }
    }
    if (roles.value.includes('hermes-project-management')) {
      children.push({
        title: t('ProjectStructureManagement'),
        icon: 'folder_managed',
        to: {
          name: 'project-structure-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-projects-information')) {
      children.push({
        title: t('InformationOfProjects'),
        icon: 'info',
        to: {
          name: 'projects-information',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-maintenance-pool-view')) {
      children.push({
        title: t('MaintenancePool'),
        icon: 'assignment',
        to: {
          name: 'maintenance-pool',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-annex-pool-management')) {
      children.push({
        title: t('AnnexPool'),
        icon: 'folder_data',
        to: {
          name: 'folder-view',
          params: { id: 'annex-pool' },
        },
      })
    }
    if (roles.value.includes('hermes-verification-protocol-pool-view')) {
      children.push({
        title: t('VerificationProtocolPool'),
        icon: 'domain_verification',
        to: {
          name: 'verification-protocol-pool',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-activity-create') || roles.value.includes('hermes-activity-perform')) {
      children.push({
        title: t('Activities'),
        icon: 'call_to_action',
        to: {
          name: 'revision-management-edition',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-workflow-management')) {
      children.push({
        title: t('Workflows'),
        icon: 'call_to_action',
        to: {
          name: 'revision-management-revision',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-spare-part-management')) {
      children.push({
        title: t('SparePartManagement'),
        icon: 'construction',
        to: {
          name: 'spare-part-view',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-supplier-management')) {
      children.push({
        title: t('SupplierManagement'),
        icon: 'warehouse',
        to: {
          name: 'supplier-view',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-standard-sentence-type-management')) {
      children.push({
        title: t('StandardSentenceTypeManagement'),
        icon: 'empty_dashboard',
        to: {
          name: 'standard-sentence-type-view',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-maintenance-plan-railway-management')) {
      children.push({
        title: t('MaintenancePlanManagemnet'),
        icon: 'pending_actions',
        to: {
          name: 'maintenance-plan-railway-management',
          params: {},
        },
      })
    }
    if (roles.value.includes('hermes-release-management')) {
      children.push({
        title: t('ReleaseManagement'),
        icon: 'award_star',
        to: {
          name: 'release-management-view',
          params: {},
        },
      })
    }
    if (children.length > 0) {
      return {
        title: t('Admin'),
        icon: 'settings',
        active: children.length > 0 ? true : false,
        to: {
          name: '',
          params: {},
        },
        children: children,
      }
    } else {
      return null;
    }
  })
  const supportMenu = computed(() => {
    var children = [];
    if (roles.value.includes('hermes-support')) {
      children.push({
        title: t('KnowledgeCenter'),
        icon: 'menu_book',
        to: {
          name: 'knowledge-center',
          params: {},
        },
        isSettings: false,
      })
    }
    if (roles.value.includes('hermes-support-management')) {
      children.push({
        title: t('KnowledgeCenterManagement'),
        icon: 'menu_book',
        to: {
          name: 'knowledge-center-management',
          params: {},
        },
        isSettings: true,
      })
    }
    if (roles.value.includes('hermes-support')) {
      children.push({
        title: t('ContactUs'),
        icon: 'email',
        to: {
          name: 'contact-us',
          params: {},
        },
        isSettings: false,
      })
    }
    if (roles.value.includes('hermes-support-management')) {
      children.push({
        title: t('ManageReports'),
        icon: 'email',
        to: {
          name: 'contact-us-admin-panel',
          params: {},
        },
        isSettings: true,
      })
    }
    if (children.length > 0) {
      return {
        title: t('Support'),
        icon: 'menu_book',
        active: children.length > 0 ? true : false,
        to: {
          name: '',
          params: {},
        },
        children: children,
      }
    } else {
      return null;
    }
  })
  const supportSettingsMenu = computed(() => {
    var settingsCopy = null;
    if (supportMenu.value) {
      settingsCopy = JSON.parse(JSON.stringify(supportMenu.value))
      settingsCopy.children = supportMenu.value.children.filter((option) => {
        return option.isSettings;
      })
    }
    if (settingsCopy && settingsCopy.children?.length > 0) {
      return settingsCopy;
    } else {
      return null;
    }
  })
  const settingsItems = computed(() => {
    var array = [];
    if (adminSettingsMenu.value) {
      array.push(adminSettingsMenu.value);
    }
    if (supportSettingsMenu.value) {
      array.push(supportSettingsMenu.value);
    }
    return array;
  })

  return {
    ref,
    computed,
    watch,
    provide,
    inject,

    EventBus,
    i18n,
    t,
    router,
    lang,
    basePath,
    adminSettingsMenu,
    supportMenu,
    supportSettingsMenu,
    settingsItems,
  }
}
