<template>
  <v-menu
    v-if="supportMenu?.children?.length > 0"
    offset-y
    nudge-bottom="10"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    v-model="isOpen"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div class="button-flex button-type-1">
        <v-btn
          v-bind="attrs"
          class="d-flex align-center"
          :class="$vuetify.breakpoint.lgAndUp ? '' : 'px-0'"
          v-on="on"
          text
          min-height="60"
          min-width="60"
          z-index="8"
          @mouseover="hoverItem()"
          @mouseleave="unhoverItem()"
        >
          <v-icon class="grey-2-hover-orange material-icons-round" size="30" :aria-label="'Support'">{{
            supportMenu.icon
          }}</v-icon>
          <!--<span class="pl-2 pr-1 font-size-14 font-weight-regular" v-if="$vuetify.breakpoint.lgAndUp">{{ t('Support') }}</span>-->
          <v-icon class="grey-2-hover-orange" v-if="$vuetify.breakpoint.lgAndUp">{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
        <div>
          <v-divider
            class="main-divider divider-hover-orange"
            :style="{visibility: isHovered || isOpen ? 'visible' : 'hidden'}"
          />
        </div>
      </div>
    </template>

    <!-- Options List -->
    <v-card class="pa-0 card-list" z-index="8">
      <v-list class="pa-0">
        <div
          v-for="(item, index) in supportMenu.children"
          :key="item.title"
        >
          <v-divider v-if="index > 0" class="mx-5"/>
          <router-link :to="item.to" style="text-decoration: none">
            <v-list-item :value="item.title" class="px-5">
              <span class="grey-hover-grey pr-2 material-icons-round">{{ item.icon }}</span>
              <v-list-item-title class="font-size-12">
                <span class="main-color hover-semibold">
                  {{ item.title }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiChevronDown, } from '@mdi/js'

import { useUtils } from '@/plugins/i18n/utils'
import { ref, } from 'vue'
import Authentication from 'lib-security/src/lib-components/security.vue'
import settings from '@/components/settings/settings';

export default {
  setup() {

    // i18n
    const { t } = useUtils()

    const { supportMenu } = settings();
    
    const perms = ref([])

    const isHovered = ref(false)
    const isOpen = ref(false)

    function hoverItem() {
      isHovered.value = true
    }

    function unhoverItem() {
      isHovered.value = false
    }

    function getPermissions() {
      try {
        var tokenParsed = Authentication.methods.getTokenParsed()
        if (!tokenParsed) {
          return
        }
        perms.value = tokenParsed.realm_access.roles
      } catch (error) {
        console.log(error)
        console.log('Error status: ' + error.response.status)
      }
    }
    getPermissions()

    return {
      t,
      supportMenu,
      perms,
      isHovered,
      isOpen,
      hoverItem,
      unhoverItem,
      
      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>