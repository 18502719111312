<template>
  <v-menu
    offset-y
    nudge-bottom="10"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
    v-model="isOpen"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="button-flex button-type-1">
        <v-btn
          v-bind="attrs"
          class="d-flex px-0"
          v-on="on"
          text
          min-height="60"
          min-width="60"
          z-index="8"
          @mouseover="hoverItem()"
          @mouseleave="unhoverItem()"
        >
          <v-badge
            v-if="notificationList.filter((elem) => !elem.readMark).length > 0"
            :content="notificationList.filter((elem) => !elem.readMark).length"
            overlap
            bordered
            class="main-badge"
            :color="'#EA514F'"
          >
            <v-icon
              class="grey-2-hover-orange"
              size="30"
              :aria-label="'Notifications'"
            >
              {{ icons.mdiBell }}
            </v-icon>
          </v-badge>
          <v-icon
            v-else
            class="grey-2-hover-orange"
            size="30"
            :aria-label="'Notifications'"
          >
            {{ icons.mdiBell }}
          </v-icon>
        </v-btn>
        <div>
          <v-divider class="main-divider divider-hover-orange" :style="{visibility: isHovered || isOpen ? 'visible' : 'hidden'}"/>
        </div>
      </div>
    </template>
    <v-card id="app-bar-notifications-content-container" class="pa-0" width="350px" z-index="8">
      <div
        class="d-flex align-center justify-space-between flex-grow-1 mx-5 py-5 cursor-pointer"
        @click="
          if ($router.route != 'notifications') {
            $router.push({name: 'notifications'})
          }
        "
      >
        <label class="main-color font-weight-bold font-size-13 cursor-pointer">{{ t('Notifications') }}</label>
      </div>
      <v-divider class="mx-5"/>
      <div
        v-if="notificationList.length === 0"
        class="pa-5"
      >
        {{ t('ThereAreNoNotifications') }}
      </div>
      <v-data-table
        v-else
        :headers="tableColumns"
        :items="notificationList"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('DataTable.LoadingText')"
        :no-data-text="t('DataTable.NoDataText')"
        :no-results-text="t('DataTable.NoResultsText')"
        hide-default-header
        :footer-props="{
          'items-per-page-text': '',
          'items-per-page-options': [],
          'page-text': t('DataTable.FooterProps.PageText'),
        }"
        class="px-5"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index"
              class="notification-row cursor-pointer"
              :class="[(item.readMark ? 'read-notification' : 'unread-notification'), (hoveredItems[index] ||
                $route.params.notificationId === item.notificationId ? 'hovered-notification' : '')]"
              @click="
                if ($router.route != 'notification' && $route.params.notificationId != item.notificationId) {
                  $router.push({name: 'notification', params: {notificationId: item.notificationId}})
                }
              "
              @mouseover="hoveredItems[index] = true"
              @mouseleave="hoveredItems[index] = false"
            >
              <td class="px-2" style="width: 50px !important;">
                <v-icon class="notification-icon">
                  {{ icons.mdiBell }}
                </v-icon>
              </td>
              <td class="px-2">
                <v-row class="grey-color-1" style="max-width: 190px;">
                  <label
                    class="font-size-12 notification-title cursor-pointer"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >
                    {{ item.title }}
                  </label>
                </v-row>
                <v-row class="grey-color-1">
                  <label class="notification-date cursor-pointer">
                    {{ item.date }}
                  </label>
                </v-row>
              </td>
              <td class="px-2" style="width: 75px !important;" align="center">
                <v-menu
                  bottom
                  left
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="notification-menu-button">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <v-card class="card-list">
                    <v-list
                      class="py-0"
                    >
                      <v-list-item
                        @mouseover="hoveredItems[index] = true"
                        @mouseleave="hoveredItems[index] = false"
                        @click="changeNotificationRead(item)"
                      >
                        <v-list-item-title class="d-flex align-center">
                          <span
                            class="material-icons-round font-size-20 grey-hover-grey mr-2"
                          >{{ item.readMark ? 'visibility_off' : 'visibility' }}</span>
                          <span
                            class="main-color hover-semibold"
                          >{{ item.readMark ? t('MarkAsNotRead') : t('MarkAsRead') }}</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider/>
                      <v-list-item
                        @mouseover="hoveredItems[index] = true"
                        @mouseleave="hoveredItems[index] = false"
                        @click="selectedNotification = item; isDeleteDialogVisible = true;"
                      >
                        <v-list-item-title class="d-flex align-center">
                          <span
                            class="material-icons-round font-size-20 grey-hover-grey mr-2"
                          >delete</span>
                          <span
                            class="main-color hover-semibold"
                          >{{ t('DeleteNotification') }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isDeleteDialogVisible"
      persistent
      width="700"
    >
      <notification-card
        :id="'app-bar-notification-list'"
        :notification-data="deleteDialogData"
        @notification-card-action="notificationCardAction"
      />
    </v-dialog>
  </v-menu>
</template>

<script>
import { mdiBell, mdiDotsVertical, } from '@mdi/js'
import { getInitialName } from '@core/utils'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { i18n } from '@/plugins/i18n'
import { useUtils } from '@/plugins/i18n/utils'
import { ref, computed, watch, } from 'vue'
import { EventBus } from '@/plugins/event-bus';

import Authentication from 'lib-security/src/lib-components/security.vue'
import LibNotificationRs from 'lib-notification-rs/src/lib-components/notification-rs.vue'
import NotificationCard from "lib-hermes-components/src/lib-components/notification-card/notification-card.vue";

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,

    NotificationCard,
  },
  setup() {

    // i18n
    const { t } = useUtils()

    const tableColumns = computed (() => {
      return [
        { text: t('Name'), value: 'id'},
        {
          text: t('Actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    })

    const loading = ref(false)
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['creationDate'],
      sortDesc: [true],
    })

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const isHovered = ref(false)
    const isOpen = ref(false)

    function hoverItem() {
      isHovered.value = true
    }

    function unhoverItem() {
      isHovered.value = false
    }

    const lang = computed(() => {
      return i18n.locale;
    });

    const dateOptions = computed(() => {
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return options;
    });

    const notificationList = ref([]);

    const hoveredItems = ref(Array(notificationList.value.length).fill(false))

    const selectedNotification = ref(null);
    const isDeleteDialogVisible = ref(false);

    const deleteDialogData = computed(() => {
      var message = i18n.t('DeleteNotificationMessage');
      if (selectedNotification.value) {
        message = message + '<br/>' + selectedNotification.value.title + ' (' + selectedNotification.value.date + ')';
      }
      return {
        visible: true,
        type: "action",
        prependIcon: "help",
        title: i18n.t('ConfirmDeleteNotification'),
        appendIcon: null,
        message: message,
        actions: [
          {
            prependIcon: null,
            message: t("LIB.DM.Cancel"),
            emit: "close-dialog",
          },
          {
            prependIcon: null,
            message: t("LIB.DM.Accept"),
            emit: "accept-action",
          },
        ],
      };
    });

    function notificationCardAction(action) {
      isDeleteDialogVisible.value = false;
      if (action === 'accept-action') {
        deleteNotification();
      }
    }

    async function deleteNotification() {
      var response = {};
      var deletedNotificationIds = [];
      try {
        response = await LibNotificationRs.methods.deleteNotification(selectedNotification.value.notificationId);
        deletedNotificationIds.push(selectedNotification.value.notificationId);
        selectedNotification.value = null;
      } catch (error) {
        response = error.response;
      } finally {
        var newNotificationData = {};
        newNotificationData.visible = true;
        newNotificationData.timeout = 3000;
        if (response.status >= 400) {
          newNotificationData.type = "warning";
          newNotificationData.prependIcon = "save";
          newNotificationData.title = i18n.t("Notifications.ErrorDeletingNotification");
          if (response.data) {
            newNotificationData.title =
              newNotificationData.title + ": " + response.data;
          }
          newNotificationData.appendIcon = "error";
        } else if (response.status >= 100) {
          newNotificationData.type = "success";
          newNotificationData.prependIcon = "save";
          newNotificationData.title = i18n.t("Notifications.DeletedNotification");
          newNotificationData.appendIcon = "done";
        }
        EventBus.$emit(
          "update-notification-data-default",
          newNotificationData
        );
        EventBus.$emit("deleted-notifications", deletedNotificationIds);
      }
    }

    EventBus.$on('updated-notifications', () => {
      getNotifications();
    });

    EventBus.$on('deleted-notifications', () => {
      getNotifications();
    });

    async function getNotifications() {
      var promises = [];
      //LibNotificationRs.methods.notificationTask();
      try {
        var tokenParsed = Authentication.methods.getTokenParsed();
        if (!tokenParsed) {
          return;
        }
        var userId = tokenParsed.sub;
        var tempNotificationList = [];
        promises.push(LibNotificationRs.methods.getNotificationsOfUser(userId).then((response) => {
          tempNotificationList = response.data;
        }));
        await Promise.all(promises);
        tempNotificationList.sort(function(a, b) {
          if (a.creationDate > b.creationDate) {
            return 1;
          }
          if (a.creationDate < b.creationDate) {
            return -1;
          }
          return 0;
        })
        for (var notification of tempNotificationList) {
          var date = new Date(notification.creationDate);
          notification.date = date.toLocaleDateString(lang.value, dateOptions.value);
        }
        notificationList.value = tempNotificationList;
      } catch (error) {
        console.log(error)
      }
    }

    async function changeNotificationRead(notification) {
      var newRead = !notification.readMark;
      var response = {};
      try {
        response = await LibNotificationRs.methods.changeNotificationsRead(newRead, [notification.notificationId]);
      } catch (error) {
        response = error.response;
      } finally {
        EventBus.$emit("updated-notifications");
      }
    }

    watch([lang,], () => {
      getNotifications();
    }, {
      deep: true,
    });

    function init() {
      getNotifications();
    }

    init();

    return {
      t,
      tableColumns,
      loading,
      options,
      getInitialName,
      perfectScrollbarOptions,
      isHovered,
      isOpen,
      hoverItem,
      unhoverItem,
      hoveredItems,
      notificationList,
      changeNotificationRead,
      selectedNotification,
      isDeleteDialogVisible,
      deleteDialogData,
      notificationCardAction,

      icons: {
        mdiBell,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
