var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"content-layout vertical-nav",class:[{'nav-drawer-mini': _vm.menuIsVerticalNavMini}, {'content-full': _vm.appContentWidth === 'full'}]},[(!_vm.menuIsMenuHidden)?_c('v-navigation-drawer',{ref:"layoutDrawer",staticClass:"app-navigation-menu elevation-5",staticStyle:{"z-index":"25","overflow":"visible"},attrs:{"app":"","right":_vm.$vuetify.rtl,"mini-variant":_vm.menuIsVerticalNavMini,"mini-variant-width":_vm.miniNavigation.width,"width":_vm.navigation.width},model:{value:(_vm.isVerticalNavMenuActive),callback:function ($$v) {_vm.isVerticalNavMenuActive=$$v},expression:"isVerticalNavMenuActive"}},[_c('v-slide-x-transition',[(_vm.isVerticalNavMenuActive)?_c('div',{staticClass:"d-flex align-center mini-button",style:(_vm.miniButtonStyle),on:{"click":function($event){$event.stopPropagation();_vm.$vuetify.breakpoint.mdAndDown ? _vm.isVerticalNavMenuActive = false : _vm.menuIsVerticalNavMini = !_vm.menuIsVerticalNavMini}}},[_c('v-btn',{attrs:{"min-height":"0px","min-width":"0px","height":"20px","width":"20px","color":"primary","fab":""}},[_c('span',{staticClass:"material-icons-round font-size-14"},[_vm._v(" "+_vm._s(_vm.menuIsVerticalNavMini ? 'chevron_right' : 'chevron_left')+" ")])])],1):_vm._e()]),_c('vertical-nav-menu',{attrs:{"nav-menu-items":_vm.navMenuItems,"menu-is-vertical-nav-mini":_vm.menuIsVerticalNavMini},on:{"update:menuIsVerticalNavMini":function($event){_vm.menuIsVerticalNavMini=$event},"update:menu-is-vertical-nav-mini":function($event){_vm.menuIsVerticalNavMini=$event},"close-nav-menu":function($event){_vm.isVerticalNavMenuActive = false}}})],1):_vm._e(),_c('div',{style:({
      'position': 'fixed',
      'height': _vm.messageData.height,
      'width': '100%',
      'background-color': '#EB7B26',
      'padding': '0px 5px',
      'color': 'black',
      'font-weight': '600',
      'visibility': _vm.messageData.visibility,
      'display': 'flex',
      'justify-content': 'center',
      'z-index': '5',
    })},[_c('v-spacer'),_c('div',{staticStyle:{"align-content":"center"}},[_c('label',[_vm._v(" "+_vm._s(_vm.i18n.t(_vm.messageData.text))+" ")])]),_c('v-spacer'),_c('div',[_c('v-btn',{staticStyle:{"height":"20px !important","width":"20px !important","background-color":"black !important","margin":"5px"},attrs:{"text":"","icon":""},on:{"click":function($event){_vm.messageDataOriginal.visibility = 'hidden'}}},[_c('span',{staticClass:"material-symbols-rounded font-size-13 white-color font-weight-bold"},[_vm._v(" close ")])])],1)],1),(_vm.appBarType !== 'hidden')?_c('v-app-bar',{ref:"refAppBar",staticClass:"mx-auto app-bar-static",class:[{ 'app-bar-shinked': _vm.appBarType === 'fixed' && _vm.scrollY }, { 'bg-blur': _vm.appBarIsBlurred && _vm.appBarType === 'fixed' && _vm.scrollY }],staticStyle:{"display":"grid !important","align-items":"center"},style:({
      'height': _vm.$vuetify.breakpoint.xsOnly ? '175px' : _vm.$vuetify.breakpoint.mdAndDown ? '120px' : '82px',
      'margin-top': _vm.messageData.visibility === 'hidden' ? '0px' : _vm.messageData.height,
    }),attrs:{"app":"","absolute":_vm.appBarType === 'static',"flat":"","elevate-on-scroll":_vm.appBarType !== 'static',"elevation":_vm.appBarType !== 'static' ? _vm.$vuetify.theme.isDark ? 4: 3 : 0}},[_vm._t("navbar",null,{"isVerticalNavMenuActive":_vm.isVerticalNavMenuActive,"toggleVerticalNavMenuActive":_vm.toggleVerticalNavMenuActive})],2):_vm._e(),_vm._t("v-app-content"),_c('v-main',{style:({
    'padding-left': (_vm.isVerticalNavMenuActive && !_vm.$vuetify.breakpoint.mdAndDown) ? _vm.menuIsVerticalNavMini ? '80px' : '300px' : '0px',
    'padding-top': _vm.$vuetify.breakpoint.xsOnly ? '175px': _vm.$vuetify.breakpoint.mdAndDown ? '120px': '82px',
    'padding-right': '0px',
    'padding-botom': _vm.isInEditor ? '0px' : '56px',
    'margin-top': _vm.messageData.visibility === 'hidden' ? '0px' : _vm.messageData.height,
  })},[_c('app-content-container',[(!_vm.isInEditor)?_c('page-header'):_vm._e(),_vm._t("default")],2)],1),_c('v-overlay',{staticClass:"content-overlay",attrs:{"value":_vm.$store.state.app.shallContentShowOverlay,"z-index":"4","absolute":""}}),(_vm.footerType !== 'hidden' && !_vm.isInEditor)?_c('v-footer',{class:{'mx-auto': _vm.appContentWidth !== 'full'},attrs:{"app":"","inset":"","absolute":_vm.footerType === 'static',"padless":"","color":_vm.footerType === 'static' ? 'transparent' : null}},[_c('div',{staticClass:"py-4 w-full",class:{'px-5': _vm.footerType === 'fixed'}},[_vm._t("footer")],2)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }